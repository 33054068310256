<template>
  <!--
    Used to create a sound
  -->
  <div class="soundsAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('themeSoundAddComponent.themeSound') }}
        </h4>
        <hr class="m-0 mb-3">
        <div
          class="form-group row mb-4"
        >
          <label class="col-12 col-md-2">{{ $t('themeSoundAddComponent.sound') }}</label>
          <div class="col-12 col-md-10">
            <select
              v-model="themeSoundElement.soundElementId"
              :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Sound') }]"
            >
              <option :value="null">
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(sound, soundIndex) in sounds"
                :key="`themeElementEditFontFamilyOption-${ soundIndex }`"
                :value="sound.id"
              >
                {{ sound.name }}
              </option>
            </select>
            <span
              v-show="errors.has('Sound')"
              class="badge badge-danger"
            >{{ errors.first('Sound') }}</span>
          </div>
        </div>
        <hr class="m-0 mb-3">
        <div
          class="form-group row mb-4"
        >
          <label class="col-12 col-md-2">{{ $t('themeSoundAddComponent.category') }}</label>
          <div class="col-12 col-md-10">
            <select
              v-model="themeSoundElement.category"
              :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Sound') }]"
            >
              <option :value="null">
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(category, categoryIndex) in categories"
                :key="`themeElementEditFontFamilyOption-${ categoryIndex }`"
                :value="category"
              >
                {{ category }}
              </option>
            </select>
            <span
              v-show="errors.has('Sound')"
              class="badge badge-danger"
            >{{ errors.first('Sound') }}</span>
          </div>
        </div>
        <span
          v-show="errors.has('Category')" 
          class="badge badge-danger"
        >{{ errors.first('Category') }}</span>
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!themeSoundElement.soundElementId || !themeSoundElement.category"
        @click.prevent="addThemeSound()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SoundsAdd",
  mixins: [
    errorMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      themeSoundElement: {},
      file: null,
      sounds: null,
      categories: null,
      fileName: null,
      uploadPercentage: null,
      uploadingRightNow: false
    }
  },
  created () {
    this.getSounds();
    this.getSoundCategories();
  },
  methods: {
    addThemeSound () {
        let postObject = {
            'soundElementId': this.themeSoundElement.soundElementId,
            'category': this.themeSoundElement.category,
            'themeId': this.themeId
        }
      this.axios.post(`/VideoPostProcessing/AddSoundElement2Theme`, postObject)
        .then((response) => {
          if (response && response.status && response.status == 201) {
            this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'));
            this.$emit("reload");
            this.error_clear();
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getSounds () {
      this.axios.get('/VideoPostProcessing/GetAllSoundElements')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.sounds = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSoundCategories () {
      this.axios.get('/VideoPostProcessing/GetSoundCategories')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.categories = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
